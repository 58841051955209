import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../../components/Layout'
import {Col, Container, Row} from "react-bootstrap"
import HeroHeadline from "../../components/FlexibleContent/HeroHeadline";
import {listResources, resourcesPagination} from "./ListResources.module.scss"
import StyledCard from "../../components/Common/StyledCard";
import Pagination from "../../components/Pagination";
import SubMenu from "../../components/FlexibleContent/SubMenu";
import ResourcesSearchForm from "../../components/Resources/SearchForm";
import Seo from "gatsby-plugin-wpgraphql-seo";
import {
	resourcesWrapper,
	headingWrapper
} from './ResourcesHeader.module.scss'
import MediaContact from "../../components/FlexibleContent/MediaContact";

const CategoryIndex = ({
                    location,
                   data,
                   pageContext: {
                     nextPagePath,
                     previousPagePath,
                     currPage,
                     archivePath,
                     archiveTitle,
                     numPages,
                     offset,
                     postsPerPage,
                     totalResources,
                   },
                   }) => {
  let posts = data.allWpPost.nodes
  let featuredPosts = [];

  const metaDesc = `Procopio resources by category: ${archiveTitle}`

  if (!posts.length) {
    return (
      <Layout>
        <Seo post={{ seo: {
            title: `${archiveTitle}`,
            metaDesc: metaDesc,
            opengraphDescription: metaDesc,
          }
        }} />
        
        {/* display only on tablet+ */}
        <div className={`submenu-desktop d-none d-md-block`}>
          <SubMenu location={`resources`} />
        </div>

        <div className={headingWrapper}>
          <HeroHeadline
            headline={archiveTitle}
            includeContainer={true}
            headingSize="h1"
            includePadding={true}
            removeTopPadding={true}
          >
            <ResourcesSearchForm />
          </HeroHeadline>
        </div>
        
        {/* displays only on mobile */}
        <div className={`submenu-mobile d-md-none ${resourcesWrapper}`}>
          <SubMenu location={`resources`} />
        </div>

        <Container fluid="md">
          <p>
            Nothing found.
          </p>
        </Container>

      </Layout>
    )
  }

  if (currPage === 1) {
    featuredPosts = posts.slice(0, 6);
    posts = posts.slice(6, posts.length);
  }

  return (
    <Layout>
      <Seo post={{ seo: {
          title: `${archiveTitle} Resources | Page ${currPage}`,
          metaDesc: metaDesc,
          opengraphDescription: metaDesc,
        }
      }} />
      
      {/* display only on tablet+ */}
			<div className={`submenu-desktop d-none d-md-block`}>
        <SubMenu location={`resources`} />
			</div>

      <div className={headingWrapper}>
        <HeroHeadline
          headline={archiveTitle}
          includeContainer={true}
          headingSize="h1"
          includePadding={true}
          removeTopPadding={true}
        >
          <ResourcesSearchForm />
        </HeroHeadline>
      </div>
      
      {/* displays only on mobile */}
      <div className={`submenu-mobile d-md-none ${resourcesWrapper}`}>
        <SubMenu location={`resources`} />
      </div>

      <section className={listResources}>
        <Container fluid="md">
          {featuredPosts && (
            <Row>
              {featuredPosts.map((post, index) => {
                return (
                  <Col sm={6} md={4} key={index}>
                    <StyledCard
                      backLink={location.pathname}
                      variation="CardC"
                      service={post?.resourceInformation?.service}
                      label={post?.resourceTypes?.nodes[0]?.name}
                      labelId={post?.resourceTypes?.nodes[0]?.slug}
                      href={post.uri}
                      title={post.title}
                      headingSize="title--sm"
                      image={post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
                      eventDetails={null}
                      imageSize="medium"
                    />
                  </Col>
                )
              })}
            </Row>
          )}

          {currPage === 1 && (
            <MediaContact viewType="list" />
          )}


          {posts && (
            <Row>
              {posts.map((post, index) => {
                return (
                  <Col sm={4} key={index}>
                    <StyledCard
                      backLink={location.pathname}
                      variation="CardE"
                      service={post?.resourceInformation?.service}
                      label={post?.resourceTypes?.nodes[0]?.name}
                      labelId={post?.resourceTypes?.nodes[0]?.slug}
                      href={post.uri}
                      title={post.title}
                      headingSize="title--xs"
                      showImage={false}
                      eventDetails={null}
                      imageSize="medium"
                    />
                  </Col>
                )
              })}
            </Row>
          )}

          <Pagination
            itemName={archiveTitle}
            addClass={resourcesPagination}
            currPage={currPage}
            offset={offset}
            itemsPerPage={postsPerPage}
            totalItems={totalResources}
            numPages={numPages}
            previousPagePath={previousPagePath}
            nextPagePath={nextPagePath}
            archivePath={archivePath} />
        </Container>
      </section>


    </Layout>
  )
}

export default CategoryIndex

export const pageQuery = graphql`
    query WordPressCategoryArchive($offset: Int!, $postsPerPage: Int!, $category: String!) {
        allWpPost(
            sort: { fields: [date], order: DESC }
            limit: $postsPerPage
            skip: $offset
            filter: {categories: {nodes: {elemMatch: {slug: {eq: $category}}}}}
        ) {
            nodes {
                uri
                date(formatString: "MMMM DD, YYYY")
                title
                resourceTypes{
                    nodes {
                        slug
                        name
                    }
                }
                categories {
                    nodes {
                        name
                        uri
                    }
                }
                featuredImage {
                    node {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    layout: CONSTRAINED,
                                    aspectRatio: 1.59,
                                    width: 550
                                )
                            }
                        }
                    }
                }
                resourceInformation {
                    service {
                        ... on WpService {
                            title
                            uri
                            serviceTypes {
                                nodes {
                                    slug
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
